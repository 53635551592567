import {
  ThemeProvider,
  audiDarkTheme,
  audiLightTheme,
  GlobalStyles,
} from "@audi/audi-ui-react";
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import App from "./App";
import { msalConfig } from "./authConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const root = createRoot(document.getElementById("root"));
const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

const RootComponent = () => {
  const [darkThemeEnabled, setDarkThemeEnabled] = React.useState(false);

  React.useEffect(() => {
    // Check if a theme is saved in localStorage
    const savedTheme = localStorage.getItem("theme");

    if (savedTheme) {
      setDarkThemeEnabled(savedTheme === "dark");
    } else {
      // If no theme is saved, use system preference
      const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
      setDarkThemeEnabled(matchMedia.matches);

      const handleChange = (event) => {
        setDarkThemeEnabled(event.matches);
      };

      matchMedia.addEventListener("change", handleChange);

      return () => {
        matchMedia.removeEventListener("change", handleChange);
      };
    }
  }, []);

  // Update body background color based on theme
  React.useEffect(() => {
    document.body.style.backgroundColor = darkThemeEnabled ? "black" : "white";
    // Save the theme in localStorage
    localStorage.setItem("theme", darkThemeEnabled ? "dark" : "light");
  }, [darkThemeEnabled]);

  const handleOnChange = () => {
    setDarkThemeEnabled(!darkThemeEnabled);
  };

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={darkThemeEnabled ? audiDarkTheme : audiLightTheme}>
        <BrowserRouter>
          <App
            darkThemeEnabled={darkThemeEnabled}
            handleOnChange={handleOnChange}
          />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
};

root.render(
  <MsalProvider instance={msalInstance}>
    <RootComponent />
  </MsalProvider>
);
