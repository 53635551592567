import React, { useMemo, useEffect, useState, useContext } from "react";
import {
  Layout,
  LayoutItem,
  ContentCard,
  Text,
  Divider,
  Select,
  DatePicker,
  useStyledTheme,
} from "@audi/audi-ui-react";
import Panel from "../components/Panel/Panel";
import DynamicTable from "../components/DynamicTable/DynamicTable";
import { useParams, NavLink } from "react-router-dom";
import ReactEcharts from "echarts-for-react";
import {
  getScenarioMaps,
  getScenarioTypes,
  getScenarioEgoBehaviors,
  getScenarios,
  getChartData,
  getCodeBeamerItems,
  getMeasurementsByScenarioName,
  getScenarioDetailsByName,
  getCodeBeamerItemDetailsById,
} from "../services/scenarioService";
import parse from "html-react-parser";
import { AuthContext } from "../store/AuthContext";
import CollapsibleSidebar from "../components/CollapsibleSidebar/CollapsibleSidebar";
import { color } from "echarts";

const DashboardScenario = ({ darkThemeEnabled }) => {
  const [scenarios, setScenarios] = useState([]);
  const [scenarioMaps, setScenarioMaps] = useState([]);
  const [selectedMap, setSelectedMap] = useState("all");
  const [scenarioTypes, setScenarioTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("all");
  const [scenarioEgoBehaviors, setScenarioEgoBehaviors] = useState([]);
  const [selectedEgoBehavior, setSelectedEgoBehavior] = useState("all");
  const [scenarioNames, setScenarioNames] = useState([]);
  const [selectedScenarioName, setSelectedScenarioName] = useState("");
  const [chartData1, setChartData1] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [chartData3, setChartData3] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [scenarioDetails, setScenarioDetails] = useState(null);
  const [selectedCodeBeamerItemId, setSelectedCodeBeamerItemId] = useState("");
  const [codeBeamerItemDetails, setCodeBeamerItemDetails] = useState(null);
  const { token, regionInfo } = useContext(AuthContext);
  const [recordFrom, setRecordFrom] = useState(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1); // Subtract one month
    return date;
  });
  const [recordTo, setRecordTo] = useState(new Date());

  console.log("Region Info Dashboard:", regionInfo);

  const columns = useMemo(
    () => [
      { Header: "Scenario Type", accessor: "scenarioType" },
      { Header: "Scenario Name", accessor: "scenarioName" },
      { Header: "Scenario State", accessor: "scenarioState" },
      { Header: "CB Item ID", accessor: "cbItemId" },
      { Header: "CB Item Name", accessor: "cbItemName" },
      { Header: "CB Item Type", accessor: "cbItemType" },
      {
        Header: "CB Item URL",
        accessor: "cbItemUrl",
        Cell: ({ cell: { value } }) => <>{value}</>,
      },
      { Header: "CB Item Status", accessor: "cbItemStatus" },
    ],
    []
  );

  const columnsMeasurements = useMemo(
    () => [
      { Header: "Measurement ID", accessor: "id" },
      { Header: "Measurement Type", accessor: "type" },
      {
        Header: "Data URL",
        accessor: "url",
        Cell: ({ cell: { value } }) => (
          <a href={value} target="_blank" rel="noopener noreferrer">
            Link
          </a>
        ),
      },
      { Header: "Duration (Hours)", accessor: "durationHours" },
      { Header: "Message Count", accessor: "messageCount" },
      {
        Header: "Topics",
        accessor: "topics",
        Cell: ({ cell: { value } }) =>
          Array.isArray(value) && value.length > 0 ? value.join(", ") : "",
      },
    ],
    []
  );

  const params = useParams();

  const theme = useStyledTheme(); // Access the theme

  useEffect(() => {
    // Load scenario maps on component mount
    getScenarioMaps(token, regionInfo).then((data) => {
      setScenarioMaps(["all", ...data]);
    });

    // Load scenario types on component mount
    getScenarioTypes(token, regionInfo).then((data) => {
      setScenarioTypes(["all", ...data]);
    });

    // Load scenario ego behaviors on component mount
    getScenarioEgoBehaviors(token, regionInfo).then((data) => {
      setScenarioEgoBehaviors(["all", ...data]);
    });

    // Load chart data from real WAS API and transform it to percentage
    getChartData("codebeamer", token, regionInfo).then((data) => {
      setChartData1(data);
    });

    getChartData("target", token, regionInfo).then((data) => {
      setChartData2(data);
    });
  }, [token, regionInfo]);

  useEffect(() => {
    // Define the filter object with recordFrom and recordTo
    const filters = {
      recordFrom,
      recordTo,
    };

    // Load chart data from the real WAS API and apply the filters
    getChartData("realdrive", token, regionInfo, filters).then((data) => {
      setChartData3(data);
    });
  }, [token, regionInfo, recordFrom, recordTo]);

  useEffect(() => {
    // Fetch scenario names when map, type, or ego behavior changes
    const filters = {
      map: selectedMap !== "all" ? selectedMap : undefined,
      type: selectedType !== "all" ? selectedType : undefined,
      ego_behavior:
        selectedEgoBehavior !== "all" ? selectedEgoBehavior : undefined,
    };

    getScenarios(filters, token, regionInfo).then((data) => {
      console.log("Scenarios DATA:", data);
      setScenarioNames(data.map((scenario) => scenario.name));
    });
  }, [selectedMap, selectedType, selectedEgoBehavior, token, regionInfo]);

  useEffect(() => {
    // Fetch table data based on selected scenario name
    if (selectedScenarioName) {
      getCodeBeamerItems(
        { scenarioName: selectedScenarioName },
        token,
        regionInfo
      ).then((data) => {
        // Transform data to match the table structure
        const transformedData = data.data.map((item) => ({
          scenarioType: item.type,
          scenarioName: selectedScenarioName,
          scenarioState: "Active", // Assuming active, replace as needed
          cbItemId: item.id,
          cbItemName: item.name,
          cbItemType: item.type,
          cbItemUrl: item.url,
          cbItemStatus: "Unset", // Assuming unset, replace as needed
        }));
        setTableData(transformedData);
      });
    } else {
      setTableData([]);
    }
  }, [selectedScenarioName, token, regionInfo]);

  useEffect(() => {
    if (selectedScenarioName) {
      getMeasurementsByScenarioName(
        selectedScenarioName,
        token,
        regionInfo
      ).then((data) => {
        setMeasurements(data);
      });
    } else {
      setMeasurements([]);
    }
  }, [selectedScenarioName, token, regionInfo]);

  useEffect(() => {
    if (selectedScenarioName) {
      getScenarioDetailsByName(selectedScenarioName, token, regionInfo).then(
        (data) => {
          setScenarioDetails(data);

          if (
            !!data &&
            data.codebeamer_items &&
            data.codebeamer_items.length > 0
          ) {
            setSelectedCodeBeamerItemId(data.codebeamer_items[0]); // Set first codebeamer item as selected
          } else {
            setSelectedCodeBeamerItemId(null);
          }
        }
      );
    }
  }, [selectedScenarioName, token, regionInfo]);

  useEffect(() => {
    if (selectedCodeBeamerItemId) {
      // Fetch CodeBeamer item details by ID
      getCodeBeamerItemDetailsById(
        selectedCodeBeamerItemId,
        token,
        regionInfo
      ).then((data) => {
        setCodeBeamerItemDetails(data);
      });
    } else {
      setCodeBeamerItemDetails(null);
    }
  }, [selectedCodeBeamerItemId, token, regionInfo]);

  const handleMapChange = (event) => {
    setSelectedMap(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleEgoBehaviorChange = (event) => {
    setSelectedEgoBehavior(event.target.value);
  };

  const handleScenarioNameChange = (event) => {
    setSelectedScenarioName(event.target.value);
  };

  // Helper function to generate color variations
  const generateColorVariations = (baseColor, count) => {
    let colors = [];
    const baseHue = 348; // Hue for #eb0d3f in HSL
    const baseSaturation = 89; // Saturation for #eb0d3f

    // Generate lightness values from 10% (0.1) to 90% (0.9) divided by count
    for (let i = 0; i < count; i++) {
      const lightness = 20 + (i * (80 - 10)) / (count - 1); // Scale from 10% to 90% lightness
      colors.push(`hsl(${baseHue}, ${baseSaturation}%, ${lightness}%)`);
    }

    return colors;
  };

  const chartColors = generateColorVariations("#eb0d3f", 10); // AUDI signal red

  return (
    <Layout>
      <CollapsibleSidebar
        title="Scenario Database"
        darkThemeEnabled={darkThemeEnabled}
      />
      <LayoutItem grow="1">
        <Panel spacing={theme.responsive.spacing.m}>
          <Text as="h2" id="scenario-database">
            Scenario Database
          </Text>
          <Divider spaceStackEnd="m" />
          <Layout
            align="center"
            justify="start"
            direction={{ xs: "column", l: "row" }}
            spaceStackEnd="m"
            columnGap="m"
            rowGap="m"
          >
            <LayoutItem basis={{ xs: "100%", l: "25%" }}>
              <DatePicker
                inputId="recordFrom"
                label="Record From"
                value={recordFrom}
                hideLabelOptional
                onChange={(e) => setRecordFrom(e)}
              />
            </LayoutItem>
            <LayoutItem basis={{ xs: "100%", l: "25%" }}>
              <DatePicker
                inputId="recordTo"
                label="Record To"
                value={recordTo}
                hideLabelOptional
                onChange={(e) => setRecordTo(e)}
              />
            </LayoutItem>
          </Layout>

          <Divider spaceStackEnd="m" />
          <Layout variant="flex" wrap="wrap">
            <LayoutItem
              basis={{ xs: "100%", l: "33%" }}
              grow={{ xs: "1", l: "0" }}
              shrink={{ xs: "1", l: "0" }}
              align={"center"}
            >
              <ContentCard spacing={"m"}>
                <Text type="headline5" spaceStackEnd="m">
                  Scenario Specification in Codebeamer
                </Text>
                <ReactEcharts
                  option={{
                    // title: {
                    //   text: "Referer of a Website",
                    //   subtext: "Fake Data",
                    //   left: "center",
                    // },
                    tooltip: {
                      trigger: "item",
                    },
                    backgroundColor: "rgba(128, 128, 128, 0)", // transparent
                    color: chartColors,
                    legend: {
                      bottom: -5,
                      left: "center",
                    },
                    series: [
                      {
                        name: "Access From",
                        type: "pie",
                        radius: "70%",
                        center: ["50%", "35%"],
                        data: chartData1, // Data from scenarioService
                        emphasis: {
                          itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                          },
                        },
                        label: {
                          position: "inside",
                          formatter: "{c}%",
                        },
                        itemStyle: {
                          shadowBlur: 200,
                          shadowColor: "rgba(0, 0, 0, 0)",
                        },
                      },
                    ],
                  }}
                  theme={darkThemeEnabled ? "dark" : "light"}
                />
              </ContentCard>
            </LayoutItem>
            <LayoutItem
              basis={{ xs: "100%", l: "33%" }}
              grow={{ xs: "1", l: "0" }}
              shrink={{ xs: "1", l: "0" }}
              align={"center"}
            >
              <ContentCard spacing={"m"}>
                <Text type="headline5" spaceStackEnd="m">
                  Target Scenario Coverage
                </Text>
                <ReactEcharts
                  option={{
                    // title: {
                    //   text: "Referer of a Website",
                    //   subtext: "Fake Data",
                    //   left: "center",
                    // },
                    tooltip: {
                      trigger: "item",
                    },
                    backgroundColor: "rgba(128, 128, 128, 0)", // transparent
                    color: chartColors,
                    legend: {
                      bottom: -5,
                      left: "center",
                    },
                    series: [
                      {
                        name: "Access From",
                        type: "pie",
                        radius: "70%",
                        center: ["50%", "35%"],
                        data: chartData2, // Data from scenarioService
                        emphasis: {
                          itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                          },
                        },
                        label: {
                          position: "inside",
                          formatter: "{c}%",
                        },
                        itemStyle: {
                          shadowBlur: 200,
                          shadowColor: "rgba(0, 0, 0, 0)",
                        },
                      },
                    ],
                  }}
                  theme={darkThemeEnabled ? "dark" : "light"}
                />
              </ContentCard>
            </LayoutItem>
            <LayoutItem
              basis={{ xs: "100%", l: "33%" }}
              grow={{ xs: "1", l: "0" }}
              shrink={{ xs: "1", l: "0" }}
              align={"center"}
            >
              <ContentCard spacing={"m"}>
                <Text type="headline5" spaceStackEnd="m">
                  Scenario Coverage in Measurement
                </Text>
                <ReactEcharts
                  option={{
                    // title: {
                    //   text: "Referer of a Website",
                    //   subtext: "Fake Data",
                    //   left: "center",
                    // },
                    tooltip: {
                      trigger: "item",
                    },
                    backgroundColor: "rgba(128, 128, 128, 0)", // transparent
                    color: chartColors,
                    legend: {
                      bottom: -5,
                      left: "center",
                    },
                    series: [
                      {
                        name: "Access From",
                        type: "pie",
                        radius: "70%",
                        center: ["50%", "35%"],
                        data: chartData3, // Data from scenarioService
                        emphasis: {
                          itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                          },
                        },
                        label: {
                          position: "inside",
                          formatter: "{c}%",
                        },
                        itemStyle: {
                          shadowBlur: 200,
                          shadowColor: "rgba(0, 0, 0, 0)",
                        },
                      },
                    ],
                  }}
                  theme={darkThemeEnabled ? "dark" : "light"}
                />
              </ContentCard>
            </LayoutItem>
          </Layout>
          <Text type="headline5" spaceStackEnd="m">
            Search by Scenario (yaml files)
          </Text>
          <Layout
            align="center"
            justify="between"
            direction={{ xs: "column", l: "row" }}
            spaceStackEnd="m"
            columnGap="m"
            rowGap="m"
          >
            <LayoutItem
              grow={{ xs: "1", l: "1" }}
              basis={{ xs: "100%", l: "25%" }}
            >
              <Select
                inputId="select__scenarioMap"
                label="Select Scenario Map"
                hideLabelOptional
                onChange={handleMapChange}
                value={selectedMap}
              >
                {scenarioMaps.map((map, index) => (
                  <option key={index} value={map}>
                    {map === "all" ? "All" : map}
                  </option>
                ))}
              </Select>
            </LayoutItem>
            <LayoutItem
              grow={{ xs: "1", l: "1" }}
              basis={{ xs: "100%", l: "25%" }}
            >
              <Select
                inputId="select__scenarioType"
                label="Select Scenario Type"
                hideLabelOptional
                onChange={handleTypeChange}
                fullWidth
                value={selectedType}
              >
                {scenarioTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type === "all" ? "All" : type}
                  </option>
                ))}
              </Select>
            </LayoutItem>
            <LayoutItem
              grow={{ xs: "1", l: "1" }}
              basis={{ xs: "100%", l: "25%" }}
            >
              <Select
                inputId="select__scenarioEgoBehavior"
                label="Select Scenario Ego Behavior"
                hideLabelOptional
                onChange={handleEgoBehaviorChange}
                fullWidth
                value={selectedEgoBehavior}
              >
                {scenarioEgoBehaviors.map((behavior, index) => (
                  <option key={index} value={behavior}>
                    {behavior === "all" ? "All" : behavior}
                  </option>
                ))}
              </Select>
            </LayoutItem>
            <LayoutItem
              grow={{ xs: "1", l: "1" }}
              basis={{ xs: "100%", l: "25%" }}
            >
              <Select
                inputId="select__wildwechsel"
                label="Select Scenario Name"
                hideLabelOptional
                onChange={handleScenarioNameChange}
                fullWidth
                value={selectedScenarioName}
              >
                {scenarioNames.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
              </Select>
            </LayoutItem>
          </Layout>
          <Text type="headline5" spaceStackEnd="m">
            Detected scenario name linked to '{selectedScenarioName}'
          </Text>
          <Divider />
          <DynamicTable
            columns={columns}
            data={tableData}
            selectableRows={false}
          />

          <Divider spaceStackEnd="xl" />

          <Text type="headline5">
            Measurements linked to '{selectedScenarioName}'
          </Text>
          <DynamicTable
            columns={columnsMeasurements}
            data={measurements}
            selectableRows={false}
          />

          <Divider spaceStackEnd="xl" />

          <Text type="headline5">Scenarios (yaml file) details</Text>
          {scenarioDetails && (
            <ContentCard>
              <Text type="headline6" spaceStackEnd="m">
                Scenario Name: {selectedScenarioName}
              </Text>
              <Text type="body1" spaceStackEnd="m">
                ADP URL:{" "}
                <a
                  href={scenarioDetails.adpUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </Text>
              <Text type="body1" spaceStackEnd="m">
                Author Email: {scenarioDetails.authorEmail}
              </Text>
              <Text type="body1" spaceStackEnd="m">
                Version: {scenarioDetails.version}
              </Text>
              <Text type="body1" spaceStackEnd="m">
                Map: {scenarioDetails.map}
              </Text>
              <div
                className="scrollable"
                style={{ maxHeight: "350px", overflowY: "scroll" }}
              >
                <Text type="body1">Title</Text>
                <Text type="body2">=====</Text>
                <Text type="body1">{scenarioDetails.title}</Text>
                <Text type="body1">Source</Text>
                <Text type="body2">======</Text>
                <Text type="body1">
                  <a
                    href={scenarioDetails.source}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {scenarioDetails.source}
                  </a>
                </Text>
                <Text type="body1">From the source</Text>
                <Text type="body2">===============</Text>
                <Text type="body1">Infrastructure_ct:</Text>
                <Text type="body1">{scenarioDetails.infrastructureCt}</Text>
                <Text type="body1">Street Level_ct:</Text>
                <Text type="body1">{scenarioDetails.streetLevelCt}</Text>
                <Text type="body1">Dynamic Road_ct:</Text>
                <Text type="body1">{scenarioDetails.dynamicRoadCt}</Text>
                <Text type="body1">Velocity_ct:</Text>
                <Text type="body1">{scenarioDetails.velocityCt}</Text>
                <Text type="body1">Traffic Environment_ct:</Text>
                <Text type="body1">{scenarioDetails.trafficEnvironmentCt}</Text>
                <Text type="body1">Intention_ct:</Text>
                <Text type="body1">{scenarioDetails.intentionCt}</Text>
                <Text type="body1">Test_data:</Text>
                <Text type="body1">{scenarioDetails.testData}</Text>
                <Text type="body1">Authors:</Text>
                <Text type="body1">{scenarioDetails.authors.join(", ")}</Text>
              </div>
            </ContentCard>
          )}

          <Divider spaceStackEnd="xl" />

          <Text type="headline5">CodeBeamer Item details</Text>
          {codeBeamerItemDetails && (
            <ContentCard>
              <Text type="body1" spaceStackEnd="m">
                Item ID: {codeBeamerItemDetails.id}
              </Text>
              <Text type="body1" spaceStackEnd="m">
                Item Name:{" "}
                <a
                  href={codeBeamerItemDetails.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {codeBeamerItemDetails.name}
                </a>
              </Text>
              <Text type="body1" spaceStackEnd="m">
                Street Level_ct: {parse(codeBeamerItemDetails.streetLevelCt)}
              </Text>
              <Text type="body1" spaceStackEnd="m">
                Infrastructure_ct:{" "}
                {parse(codeBeamerItemDetails.infrastructureCt)}
              </Text>
              <Text type="body1" spaceStackEnd="m">
                Ego-Manuevers_ct: {parse(codeBeamerItemDetails.egoManeuversCt)}
              </Text>
              <Text type="body1" spaceStackEnd="m">
                Static Road_ct: {parse(codeBeamerItemDetails.staticRoadCt)}
              </Text>
              <Text type="body1" spaceStackEnd="m">
                Relevant Variations_ct:{" "}
                {parse(codeBeamerItemDetails.relevantVariationsCt)}
              </Text>
              <Text type="body1" spaceStackEnd="m">
                Dynamic Road_ct: {parse(codeBeamerItemDetails.dynamicRoadCt)}
              </Text>
              <Text type="body1" spaceStackEnd="m">
                Chronological Sequence_ct:{" "}
                {parse(codeBeamerItemDetails.chronologicalSequenceCt)}
              </Text>
              <Text type="body1" spaceStackEnd="m">
                Environmental Conditions_ct:{" "}
                {parse(codeBeamerItemDetails.environmentalConditionsCt)}
              </Text>
            </ContentCard>
          )}

          <Divider spaceStackEnd="xxl" />
        </Panel>
      </LayoutItem>
    </Layout>
  );
};

export default DashboardScenario;
