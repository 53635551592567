import React, {
  useMemo,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import {
  Layout,
  LayoutItem,
  ContentCard,
  Text,
  Divider,
  Select,
  Checkbox,
  useStyledTheme,
} from "@audi/audi-ui-react";
import Panel from "../components/Panel/Panel";
import DynamicTable from "../components/DynamicTable/DynamicTable";
import { useParams } from "react-router-dom";
import {
  getCodeBeamerItemTypes,
  getCodeBeamerItems,
  getCodeBeamerTrackerNames,
  getCodeBeamerItemDetailsById,
  getDrivesData,
  getKuFuRequirements,
} from "../services/scenarioService";
import { AuthContext } from "../store/AuthContext";
import CollapsibleSidebar from "../components/CollapsibleSidebar/CollapsibleSidebar";
import parse from "html-react-parser";

const DashboardScenarioCodebeamer = ({ darkThemeEnabled }) => {
  const [codeBeamerItems, setCodeBeamerItems] = useState([]);
  const [codeBeamerItemTypes, setCodeBeamerItemTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("all");
  const [onlyReferenced, setOnlyReferenced] = useState(false);
  const [scenarioCodeBeamerTrackerNames, setCodeBeamerTrackerNames] = useState(
    []
  );
  const [selectedCodeBeamerTrackerName, setSelectedCodeBeamerTrackerName] =
    useState("all");
  const [selectedRow, setSelectedRow] = useState(null);
  const { token, regionInfo } = useContext(AuthContext);
  const [codeBeamerItemDetails, setCodeBeamerItemDetails] = useState(null);
  const [drivesData, setDrivesData] = useState([]);
  const [kuFuRequirements, setKuFuRequirements] = useState([]);
  const [pageCount, setPageCount] = useState(99);

  const firstTableColumns = useMemo(
    () => [
      { Header: "CB Item ID", accessor: "codebeamer_id" },
      { Header: "CB Summary", accessor: "summary" },
      { Header: "CB Tracker Type", accessor: "tracker_type" },
      {
        Header: "CB Item URL",
        accessor: "url",
        Cell: ({ value }) => (
          <a href={value} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        ),
      },
      {
        Header: "Updated",
        accessor: "updated",
        Cell: ({ value }) => {
          const date = new Date(value);
          return date.toLocaleString(); // Format the timestamp to local time
        },
      },
    ],
    []
  );

  const firstTableData = useMemo(() => codeBeamerItems, [codeBeamerItems]);

  // Columns and data based on HTML example for the child items table
  const secondTableColumns = useMemo(
    () => [
      { Header: "Measurement ID", accessor: "mItemId" },
      { Header: "Scenario Count", accessor: "mChildName" },
      {
        Header: "Data URL",
        accessor: "mItemUrl",
        Cell: ({ value }) => <a href={value}>{value}</a>,
      },
    ],
    []
  );

  const secondTableData = useMemo(() => {
    if (selectedRow) {
      return [
        {
          cbItemId: "fc7f7fb7-3c32-4b19-80f0-642f86df4796",
          cbChildName: "Measurement 1",
          cbItemType: "Scenario",
          cbItemUrl:
            "https://euw.gmdm.adp.cariad.digital/storeexp/datapools/euw-audi-fashaf-l3-dev/datasets/fc7f7fb7-3c32-4b19-80f0-642f86df4796",
        },
        {
          cbItemId: "9953d79c-5d0f-40b5-acb3-9c80390ac41a",
          cbChildName: "Measurement 2",
          cbItemType: "Scenario",
          cbItemUrl:
            "https://euw.gmdm.adp.cariad.digital/storeexp/datapools/euw-audi-fashaf-l3-dev/datasets/9953d79c-5d0f-40b5-acb3-9c80390ac41a",
        },
        {
          cbItemId: "2997abbd-2036-4cd9-975f-d6bcb3042668",
          cbChildName: "Measurement 3",
          cbItemType: "Scenario",
          cbItemUrl:
            "https://euw.gmdm.adp.cariad.digital/storeexp/datapools/euw-audi-fashaf-l3-dev/datasets/2997abbd-2036-4cd9-975f-d6bcb3042668",
        },
      ];
    }
    return [];
  }, [selectedRow]);

  // Define the columns for the third table
  const thirdTableColumns = useMemo(
    () => [
      { Header: "CB Item ID", accessor: "cbItemId" },
      { Header: "CB Child Name", accessor: "cbChildName" },
      { Header: "CB Item Type", accessor: "cbItemType" },
      {
        Header: "CB Item Url",
        accessor: "cbItemUrl",
        Cell: ({ value }) => (
          <a href={value} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        ),
      },
    ],
    []
  );

  // Define the data for the third table
  const thirdTableData = useMemo(
    () => [
      {
        cbItemId: "8834728",
        cbChildName: "Ausführung der Fahraufgabe im Zustand Aktiv",
        cbItemType: "Functional",
        cbItemUrl: "https://cb.vwgroup.com/cb/issue/8834728",
      },
      {
        cbItemId: "8834297",
        cbChildName: "Mittig im Fahrstreifen fahren",
        cbItemType: "Functional",
        cbItemUrl: "https://cb.vwgroup.com/cb/issue/8834297",
      },
      {
        cbItemId: "8834307",
        cbChildName: "Komfortable Längsrucke",
        cbItemType: "Functional",
        cbItemUrl: "https://cb.vwgroup.com/cb/issue/8834307",
      },
      {
        cbItemId: "8834316",
        cbChildName: "Zulässiger Geschwindigkeitsbereich",
        cbItemType: "Functional",
        cbItemUrl: "https://cb.vwgroup.com/cb/issue/8834316",
      },
      {
        cbItemId: "8834317",
        cbChildName: "Komfortable Querbeschleunigungen",
        cbItemType: "Functional",
        cbItemUrl: "https://cb.vwgroup.com/cb/issue/8834317",
      },
      {
        cbItemId: "15437717",
        cbChildName:
          "Keine Interaktion mit Fahrassistenzfunktion im Zustand Automatisiertes Fahren Level3",
        cbItemType: "Functional",
        cbItemUrl: "https://cb.vwgroup.com/cb/issue/15437717",
      },
    ],
    []
  );

  const params = useParams();

  const theme = useStyledTheme(); // Access the theme

  useEffect(() => {
    // Load CodeBeamer item types on component mount
    getCodeBeamerItemTypes(token, regionInfo).then((data) => {
      setCodeBeamerItemTypes(["all", ...data]);
    });

    // Load codeBeamerTrackerNames on component mount
    getCodeBeamerTrackerNames(token, regionInfo).then((data) => {
      setCodeBeamerTrackerNames(["all", ...data]);
    });
  }, [token, regionInfo]);

  // useEffect(() => {
  //   // Fetch CodeBeamer items when type, name, pageIndex, or pageSize changes
  //   const filters = {
  //     type: selectedType !== "all" ? selectedType : undefined,
  //     onlyReferenced: onlyReferenced,
  //     trackerName:
  //       selectedCodeBeamerTrackerName !== "all"
  //         ? selectedCodeBeamerTrackerName
  //         : undefined,
  //   };

  //   getCodeBeamerItems(filters, token, regionInfo, pageIndex, pageSize).then(
  //     (data) => {
  //       setCodeBeamerItems(data);
  //     }
  //   );
  // }, [
  //   selectedType,
  //   onlyReferenced,
  //   selectedCodeBeamerTrackerName,
  //   token,
  //   regionInfo,
  //   pageIndex,
  //   pageSize,
  // ]);

  const fetchCodeBeamerItems = useCallback(
    ({ pageIndex, pageSize }) => {
      const filters = {
        type: selectedType !== "all" ? selectedType : undefined,
        onlyReferenced: onlyReferenced,
        trackerName:
          selectedCodeBeamerTrackerName !== "all"
            ? selectedCodeBeamerTrackerName
            : undefined,
      };

      return getCodeBeamerItems(filters, token, regionInfo, pageIndex, pageSize)
        .then((response) => {
          setCodeBeamerItems(response.data);

          // Set the total number of pages based on the total count from the API
          setPageCount(Math.ceil(response.total_count / pageSize));

          // Return true if more data exists (if total_count > pageSize * pageIndex)
          return pageIndex * pageSize < response.total_count;
        })
        .catch((error) => {
          console.error("Error fetching CodeBeamer items:", error);
          return false; // Return false if there's an error
        });
    },
    [
      selectedType,
      onlyReferenced,
      selectedCodeBeamerTrackerName,
      token,
      regionInfo,
    ]
  );

  useEffect(() => {
    if (selectedRow && selectedRow.codebeamer_id) {
      // Fetch Drives Data when a row is selected
      getDrivesData(selectedRow.codebeamer_id, token, regionInfo).then(
        (data) => {
          setDrivesData(data);
        }
      );

      // Fetch KuFu Requirements when a row is selected
      getKuFuRequirements(selectedRow.codebeamer_id, token, regionInfo).then(
        (data) => {
          setKuFuRequirements(data);
        }
      );

      // Fetch CodeBeamer details for the selected row
      getCodeBeamerItemDetailsById(
        selectedRow.codebeamer_id,
        token,
        regionInfo
      ).then((data) => {
        setCodeBeamerItemDetails(data);
      });
    } else {
      setCodeBeamerItemDetails(null);
    }
  }, [selectedRow, token, regionInfo]);

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleOnlyReferencedChange = (event) => {
    setOnlyReferenced(event.target.checked);
  };

  const handleCodeBeamerTrackerNameChange = (event) => {
    setSelectedCodeBeamerTrackerName(event.target.value);
  };

  return (
    <Layout>
      <CollapsibleSidebar
        title="Scenario Database"
        darkThemeEnabled={darkThemeEnabled}
      />

      <LayoutItem grow="1">
        <Panel spacing={theme.responsive.spacing.m}>
          <Text as="h2" id="scenario-database">
            Specification Scenario in Codebeamer
          </Text>
          <Divider spaceStackEnd="m" />
          <Text type="headline5" spaceStackEnd="m">
            Search by CodeBeamer Items
          </Text>
          <Layout
            align="center"
            justify="between"
            direction={{ xs: "column", l: "row" }}
            spaceStackEnd="m"
            columnGap="m"
            rowGap="m"
          >
            <LayoutItem
              basis={{ xs: "100%", l: "33%" }}
              grow={{ xs: "1", l: "0" }}
              shrink={{ xs: "1", l: "0" }}
            >
              <Select
                inputId="select__itemType"
                label="CodeBeamer Item Type"
                hideLabelOptional
                onChange={handleTypeChange}
                value={selectedType}
              >
                {codeBeamerItemTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type === "all" ? "All" : type}
                  </option>
                ))}
              </Select>
            </LayoutItem>
            <LayoutItem
              basis={{ xs: "100%", l: "33%" }}
              grow={{ xs: "1", l: "0" }}
              shrink={{ xs: "1", l: "0" }}
              align={"end"}
            >
              <Checkbox
                hideLabelOptional
                inputId="checkbox-basic"
                value={onlyReferenced}
                onChange={handleOnlyReferencedChange}
              >
                Show only referenced items
              </Checkbox>
              {/* <Select
                inputId="select__itemName"
                label="CodeBeamer Item Name"
                hideLabelOptional
                onChange={handleNameChange}
                value={selectedName}
              >
                {codeBeamerItemNames.map((name, index) => (
                  <option key={index} value={name}>
                    {name === "all" ? "All" : name}
                  </option>
                ))}
              </Select> */}
            </LayoutItem>
            <LayoutItem
              basis={{ xs: "100%", l: "33%" }}
              grow={{ xs: "1", l: "0" }}
              shrink={{ xs: "1", l: "0" }}
            >
              <Select
                inputId="select__codeBeamerTrackerName"
                label="CodeBeamer Tracker Name"
                hideLabelOptional
                onChange={handleCodeBeamerTrackerNameChange}
                value={selectedCodeBeamerTrackerName}
              >
                {scenarioCodeBeamerTrackerNames.map((name, index) => (
                  <option key={index} value={name}>
                    {name === "all" ? "All" : name}
                  </option>
                ))}
              </Select>
            </LayoutItem>
          </Layout>

          <Divider />
          <Text type="headline5" spaceStackEnd="m">
            Inspect a CodeBeamer item by selecting it (Click on its row!)
          </Text>
          <DynamicTable
            columns={firstTableColumns} // Use columns from HTML example
            data={firstTableData} // Use data from service
            onRowClick={setSelectedRow} // Capture selected row
            fetchData={fetchCodeBeamerItems}
            pageCount={pageCount}
          />
          <Divider spaceStackEnd="m"></Divider>
          {selectedRow && (
            <>
              <Text type="headline5" spaceStackEnd="m">
                Drives Data in which '{selectedRow.summary}' is present
              </Text>
              <DynamicTable
                columns={secondTableColumns} // Use child item columns from HTML example
                data={drivesData} // Use child item data based on selection
              />

              <Divider spaceStackEnd="m"></Divider>
              <Text type="headline5" spaceStackEnd="m">
                CodeBeamer information associated to '{selectedRow.summary}'
              </Text>

              {codeBeamerItemDetails && (
                <ContentCard>
                  <Text type="body1" spaceStackEnd="m">
                    Item ID: {codeBeamerItemDetails.id}
                  </Text>
                  <Text type="body1" spaceStackEnd="m">
                    Item Name:{" "}
                    <a
                      href={codeBeamerItemDetails.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {codeBeamerItemDetails.name}
                    </a>
                  </Text>
                  <Text type="body1" spaceStackEnd="m">
                    Street Level_ct:{" "}
                    {parse(codeBeamerItemDetails.streetLevelCt)}
                  </Text>
                  <Text type="body1" spaceStackEnd="m">
                    Infrastructure_ct:{" "}
                    {parse(codeBeamerItemDetails.infrastructureCt)}
                  </Text>
                  <Text type="body1" spaceStackEnd="m">
                    Ego-Manuevers_ct:{" "}
                    {parse(codeBeamerItemDetails.egoManeuversCt)}
                  </Text>
                  <Text type="body1" spaceStackEnd="m">
                    Static Road_ct: {parse(codeBeamerItemDetails.staticRoadCt)}
                  </Text>
                  <Text type="body1" spaceStackEnd="m">
                    Relevant Variations_ct:{" "}
                    {parse(codeBeamerItemDetails.relevantVariationsCt)}
                  </Text>
                  <Text type="body1" spaceStackEnd="m">
                    Dynamic Road_ct:{" "}
                    {parse(codeBeamerItemDetails.dynamicRoadCt)}
                  </Text>
                  <Text type="body1" spaceStackEnd="m">
                    Chronological Sequence_ct:{" "}
                    {parse(codeBeamerItemDetails.chronologicalSequenceCt)}
                  </Text>
                  <Text type="body1" spaceStackEnd="m">
                    Environmental Conditions_ct:{" "}
                    {parse(codeBeamerItemDetails.environmentalConditionsCt)}
                  </Text>
                </ContentCard>
              )}

              <Divider spaceStackEnd="m"></Divider>
              <Text type="headline5" spaceStackEnd="m">
                KuFu requirements of '{selectedRow.summary}'
              </Text>
              <DynamicTable
                columns={thirdTableColumns}
                data={kuFuRequirements}
              />
            </>
          )}
        </Panel>
      </LayoutItem>
    </Layout>
  );
};

export default DashboardScenarioCodebeamer;
