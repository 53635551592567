import { Fragment } from "react";

function GrafanaPanel(props) {
  return (
    <Fragment>
      <h5>{props.title}</h5>
    <iframe
      title={props.title}
      src={props.url}
      width={props.width} //"100%"
      height={props.height} //"100%"
      frameBorder="0"
    />
    </Fragment>
  );
}

export default GrafanaPanel;