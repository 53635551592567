import { AuthContext } from "../../store/AuthContext";
import { useContext } from "react";
import { Select } from "@audi/audi-ui-react";

function RegionSelect(props) {
  function setSelectedRegionHandler(event) {
    let newRegion = event.target.value;
    if (!!newRegion) {
      updateRegionInfo(newRegion);
    }
  }

  const { regionInfo, updateRegionInfo } = useContext(AuthContext);
  const regions = ["EU", "US", "CN"];

  return (
    <Select
      inputId="region"
      label="Region"
      value={regionInfo}
      hideLabelOptional
      onChange={setSelectedRegionHandler}
      spaceInlineEnd="m"
    >
      {regions.map((region) => (
        <option key={region} value={region}>
          {region}
        </option>
      ))}
    </Select>
  );
}

{
  /* <select
      value={regionInfo}
      onChange={setSelectedRegionHandler}
    >
      {regions.map((region) => (
        <option key={region} value={region}>
          {region}
        </option>
      ))}
    </select> */
}

export default RegionSelect;
