// import React, { useState, useEffect } from "react";
import {
  Button,
  Layout,
  LayoutItem,
  useStyledTheme,
  ContentCard,
  Text,
  //   LayoutContainer,
  //   Loader,
} from "@audi/audi-ui-react";
// import ExternalContent from "./components/ExternalContent/ExternalContent";
import Panel from "../components/Panel/Panel";
import { useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";

function Dashboard() {
  const theme = useStyledTheme();
  const params = useParams();

  return (
    <Panel theme={theme}>
      <Text as="h2" variant="display3" spaceInlineStart="m" spaceStackStart="m">
        Page - {params.productId}
      </Text>

      <ContentCard spacing={"m"}>
        <Text variant="order3" spaceStackEnd="xxs">
          To be Created...
        </Text>
        <Button variant="secondary" spaceStackStart="l" href={"/"}>
          Back
        </Button>
      </ContentCard>
    </Panel>
  );
}

export default Dashboard;
