import React from "react";
import styled from "styled-components";
import { useStyledTheme } from "@audi/audi-ui-react";

// Define the Panel styled-component
const StyledPanel = styled.div`
  background-color: ${(props) =>
    `var(${props.theme.colors.background.level[0]})`};
  padding: ${(props) => `var(${props.spacing}, 0px)`};
`;

const Panel = ({ children, spacing }) => {
  const theme = useStyledTheme(); // Access the theme
  return (
    <StyledPanel theme={theme} spacing={spacing}>
      {children}
    </StyledPanel>
  ); // Apply the theme and render children
};

export default Panel;
