import React, { useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import {
  Text,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Pagination,
  useStyledTheme,
} from "@audi/audi-ui-react";

const DynamicTable = ({ columns, data, onRowClick, fetchData, pageCount }) => {
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true); // Track if more pages are available
  const theme = useStyledTheme();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    page,
    gotoPage, // Manual pagination function from react-table
    setPageSize,
    state: { pageIndex = 0, pageSize = 10, globalFilter },
  } = useTable(
    {
      columns,
      data, // Pass in the data
      initialState: { pageIndex: 0, pageSize: 10 }, // Set initial page index and page size
      manualPagination: true, // Enable manual pagination (external data fetching)
      pageCount, // This value is updated based on total_count from the API
    },
    useGlobalFilter,
    usePagination
  );

  // UseEffect to fetch new data when pageIndex or pageSize changes
  useEffect(() => {
    if (typeof pageIndex === "number" && fetchData) {
      fetchData({ pageIndex, pageSize }).then((hasMore) => {
        setHasNextPage(hasMore);
      });
    }
  }, [fetchData, pageIndex, pageSize]);

  const headerStyle = {
    boxSizing: "border-box",
    width: "auto",
    textAlign: "start",
    padding: `var(${theme.responsive.spacing.s}) var(${theme.responsive.spacing.s}) var(${theme.responsive.spacing.l}) 2px`,
    borderTop: `2px solid var(${theme.colors.ui.primary})`,
    borderBottom: `1px solid var(${theme.colors.ui.primary})`,
    background: `var(${theme.colors.background.level[1]})`,
    fontWeight: `var(${theme.fontWeights.bold})`,
    fontFamily: `var(${theme.responsive.typography.copy1.fontFamily})`,
    fontSize: `var(${theme.responsive.typography.copy1.fontSize})`,
    fontStretch: `var(${theme.responsive.typography.copy1.fontStretch})`,
    lineHeight: `var(${theme.responsive.typography.copy1.lineHeight})`,
    color: `var(${theme.colors.ui.secondary})`,
    cursor: "pointer",
  };

  const sortIndicatorStyle = {
    display: "inline-block",
    width: "20px",
    textAlign: "center",
  };

  const handleRowClick = (row) => {
    if (!!onRowClick) {
      setSelectedRowId(row.id);
      onRowClick(row.original);
    }
  };

  const rowStyle = (rowId) => ({
    backgroundColor:
      rowId === selectedRowId ? "rgba(235,13,63, 0.5)" : "transparent",
    cursor: !!onRowClick ? "pointer" : "normal",
  });

  return (
    <>
      <div className="mb-3">
        <input
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="form-control"
        />
      </div>
      <Table {...getTableProps()} spaceStackEnd="m">
        <TableHeader>
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <TableRow
              key={headerGroupIndex}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, columnIndex) => {
                const { key, ...rest } = column.getHeaderProps();
                return (
                  <th key={columnIndex} {...rest} style={headerStyle}>
                    <Text as="span" variant="copy1" weight="bold">
                      {column.render("Header")}
                      <span style={sortIndicatorStyle}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " ▲"
                            : " ▼"
                          : " "}
                      </span>
                    </Text>
                  </th>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <TableRow
                key={rowIndex}
                {...row.getRowProps()}
                onClick={() => handleRowClick(row)}
                style={rowStyle(row.id)}
              >
                {row.cells.map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    {...cell.getCellProps()}
                    columnHeading={cell.column.Header}
                  >
                    <Text as="span" variant="copy1">
                      {cell.render("Cell")}
                    </Text>
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Pagination
        page={pageIndex + 1} // Display 1-based index for users
        variant="extended"
        onChange={(value) => gotoPage(value - 1)} // Update the page index
        count={pageCount} // Set the total pages based on the API response
        disabled={pageIndex >= pageCount - 1} // Disable if no more pages are available
      />

      <div className="d-flex justify-content-between align-items-center mt-3">
        <Select
          label="Rows per page"
          hideLabelOptional
          value={pageSize}
          spaceStackEnd="m"
          onChange={(e) => setPageSize(Number(e.target.value))} // Change the page size
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </Select>
      </div>
    </>
  );
};

export default DynamicTable;
