import React from "react";
import { Text } from "@audi/audi-ui-react";
import "bootstrap/dist/css/bootstrap.min.css";

function FooterApp({ darkThemeEnabled }) {
  return (
    <footer
      className={`py-3 mt-auto ${darkThemeEnabled ? "bg-dark text-white" : ""}`}
    >
      <div className="container text-center">
        <Text>
          This system contains confidential data (according to VW Group
          classification)
        </Text>
      </div>
    </footer>
  );
}

export default FooterApp;
