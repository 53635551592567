// authConfig.js - prod
export const msalConfig = {
    auth: {
        clientId: "6b592982-351d-4fc1-ada4-0cd80ea091f6", // Replace with your Azure AD app client ID
        authority: "https://login.microsoftonline.com/9664fb57-6ab5-4cf3-8c69-2392f2ac06a3", // Replace with your tenant ID
        redirectUri: "https://app.demosoftsk.space", // Your redirect URI
        postLogoutRedirectUri: "https://app.demosoftsk.space", 
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const loginRequest = {
    scopes: ["User.Read"]
};