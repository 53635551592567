import React, { useEffect, useState, useContext, useMemo } from "react";
import {
  Layout,
  LayoutItem,
  Text,
  Divider,
  Select,
  DatePicker,
  useStyledTheme,
} from "@audi/audi-ui-react";
import Panel from "../components/Panel/Panel";
import DynamicTable from "../components/DynamicTable/DynamicTable";
import CollapsibleSidebar from "../components/CollapsibleSidebar/CollapsibleSidebar";
import ReactEcharts from "echarts-for-react";
import {
  getVehicleVINs,
  getRecordCities,
  getMeasurementTypes,
  getMeasurementData,
  getPercentageScenarioGraphData,
  getExactScenarioGraphData,
  getScenariosViewData,
  getDetectionsData,
  getCBItemsData,
} from "../services/scenarioService";
import { AuthContext } from "../store/AuthContext";

const DashboardMeasurements = ({ darkThemeEnabled }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState(null); // State for selected row in the second table
  const [scenarioDistributionData, setScenarioDistributionData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [detections, setDetections] = useState([]); // State for detections table data
  const [cbItems, setCbItems] = useState([]); // State for CB items table data
  const [measurementItems, setMeasurementItems] = useState([]);

  const [recordFrom, setRecordFrom] = useState(new Date());
  const [vehicleVIN, setVehicleVIN] = useState("All");
  const [vehicleVINs, setVehicleVINs] = useState([]);
  const [recordCity, setRecordCity] = useState("All");
  const [recordCities, setRecordCities] = useState([]);
  const [measurementType, setMeasurementType] = useState("all");
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [scenarioTableData, setScenarioTableData] = useState([]);
  const { token, regionInfo } = useContext(AuthContext);

  const handleRowClick = (row) => {
    setSelectedRows([row]);
    setSelectedScenario(null);
  };

  const handleScenarioRowClick = (row) => {
    setSelectedScenario(row); // Update the selected scenario
  };

  const theme = useStyledTheme();

  const firstTableColumns = [
    { Header: "Measurement ID", accessor: "id" },
    { Header: "Data URL", accessor: "url" },
    { Header: "Duration (Hours)", accessor: "duration" },
    { Header: "Message Count", accessor: "messageCount" },
    { Header: "Topics", accessor: "topics" },
  ];

  const firstTableData = useMemo(() => measurementItems, [measurementItems]);

  const scenarioColumns = [
    { Header: "Scenario Type", accessor: "scenarioType" },
    { Header: "Scenario Name", accessor: "scenarioName" },
    { Header: "Scenario Count", accessor: "scenarioCount" },
    { Header: "Scenario State", accessor: "scenarioState" },
    { Header: "CB Item ID", accessor: "cbItemId" },
    { Header: "CB Item Name", accessor: "cbItemName" },
    { Header: "CB Item Type", accessor: "cbItemType" },
    { Header: "CB Item URL", accessor: "cbItemUrl" },
    { Header: "CB Item Status", accessor: "cbItemStatus" },
  ];

  const detectionColumns = [
    { Header: "", accessor: "id" },
    { Header: "Timestamp", accessor: "timestamp" },
    { Header: "Timestamp Start", accessor: "timestampStart" },
    { Header: "Timestamp End", accessor: "timestampEnd" },
    { Header: "Duration (Seconds)", accessor: "duration" },
    { Header: "Run ID", accessor: "runId" },
    { Header: "Source", accessor: "source" },
    { Header: "Detector", accessor: "detector" },
    { Header: "Event Chain", accessor: "eventChain" },
  ];

  const cbItemColumns = [
    { Header: "", accessor: "id" },
    { Header: "CB Item ID", accessor: "cbItemId" },
    { Header: "CB Child Name", accessor: "cbChildName" },
    { Header: "CB Item Type", accessor: "cbItemType" },
    { Header: "CB Item URL", accessor: "cbItemUrl" },
    { Header: "CB Item Status", accessor: "cbItemStatus" },
    { Header: "CB Item Description", accessor: "cbItemDescription" },
  ];

  useEffect(() => {
    getVehicleVINs(token, regionInfo).then((data) =>
      setVehicleVINs(["All", ...data])
    );
    getRecordCities(token, regionInfo).then((data) =>
      setRecordCities(["All", ...data])
    );
    getMeasurementTypes(token, regionInfo).then((data) =>
      setMeasurementTypes([...data])
    );
  }, [token, regionInfo]);

  useEffect(() => {
    // Format the recordFrom date in UTC if it is defined
    const formattedRecordFrom = recordFrom
      ? recordFrom.toISOString()
      : undefined;

    // Construct filters based on the selected filter values
    const filters = {
      recordFrom: formattedRecordFrom, // Send in UTC format
      vehicleVIN: vehicleVIN !== "" ? vehicleVIN : undefined,
      recordCity: recordCity !== "" ? recordCity : undefined,
      measurementType: measurementType !== "" ? measurementType : undefined,
    };

    // Fetch the filtered measurement data
    getMeasurementData(token, regionInfo, filters)
      .then((data) => {
        setMeasurementItems(data);
      })
      .catch((error) => {
        console.error("Error fetching measurement data:", error);
      });
  }, [recordFrom, vehicleVIN, recordCity, measurementType, token, regionInfo]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      const mItemId = selectedRows[0].id;
      getPercentageScenarioGraphData(token, regionInfo, mItemId)
        .then((data) => {
          if (data.length > 0) {
            setScenarioDistributionData(data);
          } else {
            setScenarioDistributionData([]);
          }
        })
        .catch((error) => {
          console.error(
            "Error fetching scenario percentage distribution data",
            error
          );
        });

      getExactScenarioGraphData(token, regionInfo, mItemId)
        .then((data) => {
          if (data.length > 0) {
            setBarChartData(data.sort((a, b) => a.value - b.value));
          } else {
            setBarChartData([]);
          }
        })
        .catch((error) => {
          console.error(
            "Error fetching scenario exact distribution data",
            error
          );
        });

      getScenariosViewData(token, regionInfo, mItemId)
        .then((tableData) => {
          if (tableData.length > 0) {
            setScenarioTableData(tableData);
          } else {
            setScenarioTableData([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching scenario table data", error);
        });
    }
  }, [selectedRows, token, regionInfo]);

  useEffect(() => {
    if (selectedRows.length > 0 && selectedScenario) {
      const mItemId = selectedRows[0].id;
      const scenarioName = selectedScenario.scenarioName;

      // Fetch Detections Data
      getDetectionsData(token, regionInfo, mItemId, scenarioName)
        .then((data) => {
          if (data.length > 0) {
            setDetections(data);
          } else {
            setDetections([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching detections data", error);
        });

      // Fetch CB Items Data
      getCBItemsData(token, regionInfo, mItemId, scenarioName)
        .then((data) => {
          if (data.length > 0) {
            setCbItems(data);
          } else {
            setCbItems([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching CB items data", error);
        });
    }
  }, [selectedRows, selectedScenario, token, regionInfo]);

  return (
    <Layout>
      <CollapsibleSidebar
        title="Scenario Database"
        darkThemeEnabled={darkThemeEnabled}
      />

      <LayoutItem grow="1">
        <Panel spacing={theme.responsive.spacing.m}>
          <Text as="h2" id="measurements-datalake">
            Measurements in Datalake
          </Text>

          <Divider spaceStackEnd="m" />

          <Text type="headline5">Search by Drives Data</Text>

          <Layout variant="flex" wrap="wrap">
            <LayoutItem basis={{ xs: "100%", l: "25%" }}>
              <DatePicker
                inputId="recordFrom"
                label="Record From"
                value={recordFrom}
                hideLabelOptional
                onChange={(e) => setRecordFrom(e)}
              />
            </LayoutItem>
            <LayoutItem basis={{ xs: "100%", l: "25%" }}>
              <Select
                inputId="vehicleVIN"
                label="Vehicle VIN"
                value={vehicleVIN}
                hideLabelOptional
                onChange={(e) => setVehicleVIN(e.target.value)}
              >
                {vehicleVINs.map((vin, index) => (
                  <option key={index} value={vin}>
                    {vin}
                  </option>
                ))}
              </Select>
            </LayoutItem>
            <LayoutItem basis={{ xs: "100%", l: "25%" }}>
              <Select
                inputId="recordCity"
                label="Record City"
                value={recordCity}
                hideLabelOptional
                onChange={(e) => setRecordCity(e.target.value)}
              >
                {recordCities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </Select>
            </LayoutItem>
            <LayoutItem basis={{ xs: "100%", l: "25%" }}>
              <Select
                inputId="measurementType"
                label="Measurement Type"
                value={measurementType}
                hideLabelOptional
                onChange={(e) => setMeasurementType(e.target.value)}
              >
                {measurementTypes.map((id, index) => (
                  <option key={index} value={id}>
                    {id}
                  </option>
                ))}
              </Select>
            </LayoutItem>
          </Layout>

          <Divider spaceStackEnd="m" />

          <Text type="headline5" spaceStackEnd="m">
            Inspect a measurement by selecting it
          </Text>

          <DynamicTable
            columns={firstTableColumns}
            data={firstTableData}
            onRowClick={handleRowClick}
            selectableRows
            selectedRows={selectedRows}
          />

          {selectedRows.length > 0 && (
            <>
              <Divider spaceStackEnd="m" />
              <Text type="headline5" spaceStackEnd="m">
                Scenarios distribution within '{selectedRows[0].id}'
              </Text>

              <ReactEcharts
                option={{
                  tooltip: {
                    trigger: "item",
                  },
                  legend: {
                    top: "0%",
                    left: "left",
                    orient: "vertical",
                  },
                  backgroundColor: "rgba(128, 128, 128, 0)", // transparent
                  series: [
                    {
                      name: "Scenario Distribution",
                      type: "pie",
                      radius: "90%",
                      data: scenarioDistributionData,
                      emphasis: {
                        itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                      },
                      label: {
                        position: "inside",
                        formatter: "{c}%",
                      },
                      itemStyle: {
                        shadowBlur: 200,
                        shadowColor: "rgba(0, 0, 0, 0)",
                      },
                    },
                  ],
                }}
                theme={darkThemeEnabled ? "dark" : "light"}
              />
            </>
          )}

          {selectedRows.length > 0 && (
            <ReactEcharts
              option={{
                tooltip: {
                  trigger: "axis",
                },
                legend: {
                  show: false,
                },
                backgroundColor: "rgba(128, 128, 128, 0)", // transparent
                grid: {
                  left: "30%", // Adjust left margin to ensure labels are visible
                  right: "10%", // Adjust right margin for better padding
                  top: "10%", // Adjust top margin for better spacing
                  bottom: "10%", // Adjust bottom margin for better spacing
                },
                xAxis: {
                  type: "value",
                  splitLine: {
                    show: false,
                  },
                },
                yAxis: {
                  type: "category",
                  splitLine: {
                    show: false,
                  },
                  axisLabel: {
                    inside: false,
                    show: true,
                    fontSize: 12,
                    padding: [0, 0, 0, 0],
                  },
                  data: barChartData.map((item) => item.name),
                },
                series: [
                  {
                    name: "Scenario Count",
                    type: "bar",
                    label: {
                      position: "right",
                      show: true,
                    },
                    data: barChartData.map((item) => item.value),
                    itemStyle: {
                      color: (params) => {
                        const colors = [
                          "#8B0000",
                          "#FF4500",
                          "#FFA500",
                          "#FFD700",
                          "#ADFF2F",
                          "#00FA9A",
                          "#20B2AA",
                          "#1E90FF",
                          "#0000FF",
                          "#4B0082",
                        ];
                        return colors[params.dataIndex];
                      },
                    },
                  },
                ],
              }}
              theme={darkThemeEnabled ? "dark" : "light"}
            />
          )}

          {selectedRows.length > 0 && (
            <>
              <Divider spaceStackEnd="m" />

              <Text type="headline5" spaceStackEnd="m">
                Select a scenario from the table below
              </Text>

              <DynamicTable
                columns={scenarioColumns}
                data={scenarioTableData}
                onRowClick={handleScenarioRowClick}
                selectableRows
                selectedRows={selectedScenario ? [selectedScenario] : []}
              />

              {selectedScenario && (
                <>
                  <Text type="headline5" spaceStackEnd="m">
                    Detections of scenario '{selectedScenario.scenarioName}' in
                    '{selectedRows[0].id}'
                  </Text>

                  {/* Detections Table */}
                  <DynamicTable
                    columns={detectionColumns}
                    data={detections}
                    selectableRows={false}
                  />

                  <Divider spaceStackEnd="m" />

                  {/* CB Items Table */}
                  <DynamicTable
                    columns={cbItemColumns}
                    data={cbItems}
                    selectableRows={false}
                  />
                </>
              )}
            </>
          )}
        </Panel>
      </LayoutItem>
    </Layout>
  );
};

export default DashboardMeasurements;
