import { Text, Layout } from "@audi/audi-ui-react";

function BlankPage({ darkThemeEnabled }) {
  return (
    <Layout justify="center">
      <div className={`${darkThemeEnabled ? "text-white" : ""}`}>
        <Text as="h3" variant="display1" verticalAlign="center">
          Please login
        </Text>
      </div>
    </Layout>
  );
}

export default BlankPage;
