import React, { useState, useEffect, Fragment, useContext } from "react";
import "./HeaderApp.css";
import { Button, Text, Toggle, Avatar } from "@audi/audi-ui-react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../../authConfig"; // Assuming you have an authConfig file
import { AuthContext } from "../../store/AuthContext";
import RegionSelect from "../RegionSelect/RegionSelect";
import { useLocation } from "react-router-dom";
import { backendConfig } from "../../backendConfig"; // Assuming you have an authConfig file

const HeaderApp = ({ darkThemeEnabled, handleOnChange }) => {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token, isLogin, userInfo, regionInfo } = useContext(AuthContext);

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  console.log("Config msal:", msalConfig);
  const handleLogin = () => {
    instance
      //.loginRedirect(loginRequest)
      .loginPopup(loginRequest)
      .then((response) => {
        console.log("Login successful:", response);
      })
      .catch((error) => {
        console.error("Login failed:", error);
      });
  };

  const handleLogout = () => {
    instance
      //.logoutRedirect()
      .logoutPopup()
      .then((response) => {
        console.log("Logout successful:", response);
      })
      .catch((error) => {
        console.error("Logout failed:", error);
      });
  };

  if (isLogin) {
    console.log("Token:", token);
    console.log("User Info:", userInfo);
  }
  useEffect(() => {
    if (!isLogin) return;
    console.log("Region Info:", regionInfo);
    fetch(`${backendConfig.host}${backendConfig.configuration}${regionInfo}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
        console.log("Data product HEADER ", data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setIsLoading(false);
      });
  }, [regionInfo, token, isLogin]);

  const getInitials = (name) => {
    if (!!name) {
      const nameParts = name.split(" ");
      const initials = nameParts
        .map((part) => part[0]) // Get the first letter of each part
        .join("")
        .toUpperCase(); // Join and convert to uppercase

      return initials.slice(0, 2); // Return only the first two letters
    } else {
      return "";
    }
  };

  // Get the initials from the name
  const initials = getInitials(userInfo?.name);

  return (
    <header
      role="banner"
      className={`gbp-header ${darkThemeEnabled ? "bg-dark text-white" : ""}`}
    >
      <Navbar expand="lg" className="gbp-navigation">
        <Container>
          <Navbar.Brand href="/">
            <img
              src="/logo.svg"
              alt="Audi Logo"
              className={`gbp-icon-logo ${
                darkThemeEnabled ? "logo-white" : ""
              }`}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {location.pathname !== "/" && location.pathname !== "/home" && (
                <Nav.Link href="/home" key="homeId">
                  Home
                </Nav.Link>
              )}
              {isLogin &&
                (isLoading ? (
                  <NavDropdown.Item key="loadingid">
                    Loading...
                  </NavDropdown.Item>
                ) : (
                  products.map((productitem, index) => {
                    const product = productitem.item;
                    if (product.submenu) {
                      console.log("submenu:", product.submenu);
                      return (
                        <NavDropdown key={product.id} title={product.title}>
                          {product.submenu.map((subItem) => (
                            <NavDropdown.Item
                              key={subItem.id}
                              href={subItem.url}
                            >
                              {subItem.title}
                            </NavDropdown.Item>
                          ))}
                        </NavDropdown>
                      );
                    } else
                      return (
                        <Nav.Link key={product.id} href={product.url}>
                          {product.title}
                        </Nav.Link>
                      );
                  })
                ))}
              {/* <Nav.Link href="#">
                <Text>Contact</Text>
              </Nav.Link>
              <Nav.Link href="#">
                <Text>Careers</Text>
              </Nav.Link> */}
            </Nav>
            <div className="d-flex align-items-center">
              <Toggle
                inputId="themeSwitch"
                on={darkThemeEnabled}
                onChange={handleOnChange}
                spaceInlineEnd="m"
              >
                dark theme
              </Toggle>

              {isAuthenticated ? (
                <Fragment>
                  <RegionSelect />
                  <Avatar
                    label={initials}
                    className="dapi-avatar"
                    size="medium"
                    spaceInlineEnd="m"
                  />{" "}
                  <Button size="small" onClick={handleLogout}>
                    Logout
                  </Button>
                </Fragment>
              ) : (
                <Button size="small" onClick={handleLogin}>
                  Login
                </Button>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default HeaderApp;
