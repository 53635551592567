import React, { createContext, useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig'; // Adjust the path as necessary

export const AuthContext = createContext({ token : null, isLogin : false, userInfo : null, regionInfo : "EU"  });

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [isLogin, setIsLogin] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [regionInfo, setRegionInfo] = useState("EU"); 
  const { instance, accounts } = useMsal();

  const updateRegionInfo = (region) => {
    setRegionInfo( (prevRegion) =>  region );
  };

  useEffect(() => {
    if (accounts.length > 0) {
      const account = accounts[0];
      setUserInfo(account);
      setIsLogin(true);

      const acquireToken = () => {
        instance.acquireTokenSilent({
          ...loginRequest,
          account: account
        }).then(response => {
          setToken(response.accessToken);
        }).catch(error => {
          console.error('Token acquisition failed:', error);
          // Fallback to interactive method if silent token acquisition fails
          instance.acquireTokenPopup(loginRequest).then(response => {
            setToken(response.accessToken);
          }).catch(error => {
            console.error('Token acquisition via popup failed:', error);
          });
        });
      };

      // Acquire token initially
      acquireToken();

      // Set up interval to refresh token periodically (e.g., every 5 minutes)
      const tokenRefreshInterval = setInterval(acquireToken, 5 * 60 * 1000);

      // Clear interval on component unmount
      return () => clearInterval(tokenRefreshInterval);
    } else {
      setIsLogin(false);
      setUserInfo(null);
      setToken(null);
    }
  }, [accounts, instance]);

  return (
    <AuthContext.Provider value={{ token, isLogin, userInfo, regionInfo, updateRegionInfo }}>
      {children}
    </AuthContext.Provider>
  );
};