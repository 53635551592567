import GrafanaPanel from "../components/GrafanaPanel/GrafanaPanel";

function GrafanaDemo() {    
    const grafanaUrl =
       //"https://visual.demosoftsk.space/d-solo/adwa4opcopy21/demo-dashboard-copy-2?orgId=1&from=1724917158306&to=1724938758306&panelId=1";      
       "https://app.demosoftsk.space/grafanaview/d-solo/adwa4opcopy21/demo-dashboard-copy-2?orgId=1&from=1725008107670&to=1725029707670&panelId=1";
      // "http://localhost:3100/d-solo/edw33qgzcej28e/new-dashboard?orgId=1&from=1724918966294&to=1724940566294&panelId=1";
  return (
    <div>
     <h3>Grafana Demo</h3>   
     <GrafanaPanel url={grafanaUrl} title="Panel of Grafana Demo" width="500" height="500" /> 
    </div>
  );
}
export default GrafanaDemo;