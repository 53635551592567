import React, { useState, useEffect, useContext } from "react";
import { Button, Collapse, Nav } from "react-bootstrap"; // Import Nav from react-bootstrap
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Layout, Text, useStyledTheme } from "@audi/audi-ui-react";
import { AuthContext } from "../../store/AuthContext";
import { backendConfig } from "../../backendConfig";
import { useLocation } from "react-router-dom"; // Import useLocation to get the current URL

const CollapsibleSidebar = ({
  title,
  defaultExpanded = true,
  darkThemeEnabled,
}) => {
  const [isOpen, setIsOpen] = useState(defaultExpanded);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token, regionInfo } = useContext(AuthContext);
  const theme = useStyledTheme();
  const location = useLocation(); // Get the current location

  // Function to toggle the sidebar
  const toggleSidebar = () => setIsOpen(!isOpen);

  // Effect to fetch products based on the token and regionInfo
  useEffect(() => {
    if (token && regionInfo) {
      fetch(
        `${backendConfig.host}${backendConfig.configuration}${regionInfo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Data product submenu", data);
          setProducts(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching products for submenu:", error);
          setIsLoading(false);
        });
    }
  }, [regionInfo, token]);

  // Effect to handle sidebar collapse on mobile resolution
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsOpen(false);
      } else {
        setIsOpen(defaultExpanded);
      }
    };

    handleResize(); // Check the window size on component mount
    window.addEventListener("resize", handleResize); // Add resize event listener

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup the event listener
    };
  }, [defaultExpanded]);

  const submenuItems =
    products.find((product) => product.item.id === 1000)?.item.submenu || [];

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          width: isOpen ? "250px" : "50px",
          transition: "width 0.3s",
          backgroundColor: `var(${theme.colors.background.level[1]})`,
          color: `var(${theme.colors.ui.secondary})`,
          borderRight: darkThemeEnabled
            ? "1px solid #495057"
            : "1px solid #dee2e6",
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            padding: `var(${theme.responsive.spacing.s})`,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: darkThemeEnabled
              ? "1px solid #495057"
              : "1px solid #dee2e6",
          }}
        >
          {isOpen && <Text>{title}</Text>}
          <Button
            variant="link"
            style={{ padding: 0, color: `var(${theme.colors.ui.secondary})` }}
            onClick={toggleSidebar}
          >
            {isOpen ? <BsChevronLeft /> : <BsChevronRight />}
          </Button>
        </div>
        <Collapse in={isOpen}>
          <div style={{ padding: `var(${theme.responsive.spacing.s})` }}>
            {isLoading ? (
              <p>Loading...</p>
            ) : submenuItems.length > 0 ? (
              submenuItems.map((item) => {
                const isActive = location.pathname === item.url; // Check if the current URL matches the item's URL

                return (
                  <Nav.Link
                    key={item.id}
                    href={item.url}
                    style={{
                      color: isActive
                        ? `var(${theme.colors.ui.primary})` // Highlight color
                        : `var(${theme.colors.ui.secondary})`,
                      backgroundColor: isActive
                        ? `var(${theme.colors.background.level[3]})` // Highlight background
                        : "transparent",
                      paddingLeft: `var(${theme.responsive.spacing.xs})`,
                      paddingBottom: `var(${theme.responsive.spacing.xs})`,
                      borderRadius: "4px", // Optional: Add border radius for better appearance
                    }}
                  >
                    {item.title}
                  </Nav.Link>
                );
              })
            ) : (
              <p>No items available</p>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default CollapsibleSidebar;
