import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import Home from "./pages/Home";
import HeaderApp from "./components/HeaderApp/HeaderApp";
import FooterApp from "./components/FooterApp/FooterApp";
import DashboardMeasurements from "./pages/DashboardMeasurements";
import DashboardScenarioCodebeamer from "./pages/DashboardScenarioCodebeamer";
import DashboardScenario from "./pages/DashboardScenario";
import Dashboard from "./pages/Dashboard";
import BlankPage from "./pages/BlankPage";
import { AuthProvider } from "./store/AuthContext";
import GrafanaDemo from "./pages/GrafanaDemo";

function App({ darkThemeEnabled, handleOnChange }) {
  const isAuthenticated = useIsAuthenticated();
  return (
    <AuthProvider>
      <HeaderApp
        darkThemeEnabled={darkThemeEnabled}
        handleOnChange={handleOnChange}
      />
      <main>
        <Switch>
          <Route path="/" exact>
            {isAuthenticated ? (
              <Redirect to="/home" />
            ) : (
              <BlankPage darkThemeEnabled={darkThemeEnabled} />
            )}
          </Route>
          <MsalAuthenticationTemplate
            interactionType="redirect"
            authenticationRequest={loginRequest}
          >
            <Route path="/home">
              <Home />
            </Route>
            <Route exact path="/grafana/demo">
              <GrafanaDemo />
            </Route>
            <Route exact path="/dashboard/scenario/landing">
              <DashboardScenario darkThemeEnabled={darkThemeEnabled} />
            </Route>
            <Route exact path="/dashboard/scenario/codebeamer">
              <DashboardScenarioCodebeamer
                darkThemeEnabled={darkThemeEnabled}
              />
            </Route>
            <Route exact path="/dashboard/scenario/measurements">
              <DashboardMeasurements darkThemeEnabled={darkThemeEnabled} />
            </Route>
            <Route exact path="/dashboard/:productId">
              <Dashboard darkThemeEnabled={darkThemeEnabled} />
            </Route>
          </MsalAuthenticationTemplate>
        </Switch>
      </main>
      <FooterApp darkThemeEnabled={darkThemeEnabled} />
    </AuthProvider>
  );
}

export default App;
