// backendConfig.js - local
export const backendConfig = {
   host : "https://api.demosoftsk.space",
   configuration : "/configuration/read/",
   scenarios: {
      maps : "/scenarios/scenario_maps/",
      scenarios : "/scenarios/scenarios/"
   }
};

